
.landing{
  cursor: url(../assets/dot.svg), auto;
}

.card{
  cursor: url(../assets/dot.svg), auto !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

// mobile styles
@media screen and (max-width: 600px) {
  .videoTag{
    display:none;
  }
  .card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}






